import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {Layout} from '../components/index';
import {getPages, Link, withPrefix} from '../utils';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

const resolveCategoryTreeForProduct = (new_categories, current_product) => {
  const { frontmatter: { category } } = current_product;
};

export default class Portfolio extends React.Component {
    state = {
      categories: {},
    };
  
    componentDidMount() {
      const display_products = _.orderBy(getPages(this.props.pageContext.pages, '/produse'), 'frontmatter.date', 'desc');

      const categories = display_products.reduce((current_categories, current_product) => {
        const { frontmatter: { category } } = current_product;

        const new_categories = JSON.parse(JSON.stringify(current_categories));

        // CASE WHEN A CATEGORY DOES NOT HAVE SUBCATEGORIES
        if (!category.parent_category) {
          if (!new_categories[category.title]) {
            new_categories[category.title] = [current_product];
          } else {
            new_categories[category.title].push(current_product);
          }
        // CASE WHEN A CATEGORY HAS SUBCATEGORIES
        } else {
          if (!new_categories[category.parent_category.title]) {
            new_categories[category.parent_category.title] = {
              [category.title]: [current_product],
            }
          } else if (!new_categories[category.parent_category.title][category.title]) {
            new_categories[category.parent_category.title][category.title] = [current_product];
          } else {
            new_categories[category.parent_category.title][category.title].push(current_product);
          }
        }

        return new_categories;
      }, {});

      this.setState({ categories });
    }

    render() {
        let display_products = _.orderBy(getPages(this.props.pageContext.pages, '/produse'), 'frontmatter.date', 'desc');
        return (
            <Layout {...this.props}>
            <div className="inner outer">
              <header className="page-header inner-sm">
                <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                <p className="page-subtitle">{_.get(this.props, 'pageContext.frontmatter.subtitle', null)}</p>
                )}
              </header>
              <Accordion 
                allowMultipleExpanded
                allowZeroExpanded
              >
                {_.map(Object.keys(this.state.categories), (category_name, category_idx) => {
                  const current_category = this.state.categories[category_name];

                  return (
                    <AccordionItem key={category_name}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {category_name}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="accordion__panel not-last-child-mb-md">
                        {Array.isArray(current_category) 
                          ? 
                            _.map(current_category, (product, product_idx) => (
                              <Link key={product.url} to={withPrefix(product.url)} style={{ display: 'flex', borderBottom: 'none', alignItems: 'center' }}>
                                {product.frontmatter.thumb_image ? <img src={withPrefix(product.frontmatter.thumb_image)} alt={product.frontmatter.title} style={{ width: 50, height: 50, objectFit: 'cover', marginRight: 20, borderRadius: '50%' }} /> : null}                                    
                                <p style={{ fontWeight: 'bold', color: '#ce3100', margin: 0 }}>{product.frontmatter.title}</p>
                              </Link>
                            )) 
                          : 
                            _.map(Object.keys(current_category), (subcategory_name, subcategory_idx) => {
                              const current_subcategory = current_category[subcategory_name];
          
                              return (
                                <div key={subcategory_name}>
                                  <h4 style={{ marginBottom: 16, marginTop: subcategory_idx !== 0 ? 24 : 0 }}>{subcategory_name}</h4>
                                  {_.map(current_subcategory, (product, product_idx) => (
                                    <Link key={product.url} to={withPrefix(product.url)} style={{ display: 'flex', borderBottom: 'none', alignItems: 'center' }}>
                                      {product.frontmatter.thumb_image ? <img src={withPrefix(product.frontmatter.thumb_image)} alt={product.frontmatter.title} style={{ width: 50, height: 50, objectFit: 'cover', marginRight: 20, borderRadius: '50%' }} /> : null}                                    
                                      <p style={{ fontWeight: 'bold', color: '#ce3100', margin: 0 }}>{product.frontmatter.title}</p>
                                    </Link>
                                ))}
                                </div>  
                            )})}
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
            </Layout>
        );
    }
}
